.App {
  text-align: center;
  background: white;
  position: relative;
}

.header {
  width: 80%;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 25px;
  left: 142px;
}

.banner {
  display: flex;
  min-height: 100vh;
  align-items: end;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background: url('./assets/banner.jpg') center center no-repeat;
}

.letter {
  width: 60%;
  margin-bottom: 70px;
}

.footer {
  height: 240px;
  background: #323232;
  padding-top: 40px;
}

.footer-logo img {
  width: 200px;
  height: 40px;
}

.footer-ico {
  width: 720;
  height: 40px;
  display: flex;
  justify-content: center;
  margin: 41px 0;
}

.footer-ico a {
  margin-right: 20px;
}

.footer-ico img {
  width: 40px;
  height: 40px;
}

.footer-bottom {
  color: #fff;
  opacity: 0.5;
  font-size: 14px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
